import React from "react"

import Banner from "./banner"
import { Link } from "../../services/routes" // "gatsby"
import routes from "../../services/routes"

export default () => (
	<>
		<Banner title="Privacy Policy" link={routes.privacy_policy} />
		<div style={{backgroundColor:"white", margin:"20px", padding:"5px", textAlign:"left"}}>
			Scratchwork operates the website <Link to={routes.index}>scratchwork.io</Link> and is committed to preserving the privacy of all
			visitors to the website. Please read the following privacy policy to understand how we use and protect the
			information that you provide to us. Please also ensure that you have read and understood our{' '}
			<Link to={routes.terms_of_service}>Terms of Service</Link>, which you agree to when you visit or use the Scratchwork website.
			This page informs you of our policies regarding the collection, as well as use and disclosure of personal
			information we receive from users of the site.
			<br />
			<br />
			We use your personal information only for providing and improving the site. By using the site, you agree to the
			collection and use of information in accordance with this policy.
			<h4>Information Collection And Use</h4>While using our site, we may ask you to provide us with certain personally
			identifiable information that can be used to contact or identify you. By submitting your personal information, you
			consent to the collection, use and transfer of your information in accordance with this privacy policy. All of your
			information provided to us is for the express purpose of{' '}
			<strong>creating your account and collecting anonymous data for improving our product. </strong>
			<br />
			<br />
			If you are 16 years old or younger you may not register with us on the Website. If we become aware that you are 16
			years old or younger and you have not obtained such permission, we will remove your personal information from our
			records. By entering your details, you are stating that you are 17 years old or above. <br />
			<br />
			Certain personal information that you provide to us is defined as Sensitive Personal Data by the Data Protection Act
			1988. We do not seek any sensitive personal data other than that which is reasonable and necessary for the purposes
			sought.
			<h4>Log Data</h4>Like many site operators, we collect information that your browser sends whenever you visit our
			site. This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type,
			browser version, the pages of our site that you visit, the time and date of your visit, the time spent on those
			pages and other statistics.
			<br />
			<br />
			In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this
			information.
			<h4>Student Data</h4>Scratchwork takes student privacy very seriously. Any student data provided to Scratchwork is
			retained solely for educational purposes. A parent may request to have student data removed from Scratchwork's
			database by submitting a request by email to: <a href="mailto:contact@scratchwork.io">contact@scratchwork.io</a>
			<h4>Cookies</h4>Cookies are files with a small amount of data, which may include an anonymous unique identifier.
			Cookies are sent to your browser from a web site and stored on your computer's hard drive.
			<br />
			<br />
			Like many sites, we use cookies to collect information. We issue cookies to your computer when you log on to the
			website and register with our service. Cookies are necessary for the operation of the service. You can instruct your
			browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you
			may not be able to use some portions of our site.
			<h4>Security</h4>The security of your personal information is important to us, but remember that no method of
			transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially
			acceptable means to protect your personal information, we cannot guarantee its absolute security.
			<h4>Changes To This Privacy Policy</h4>This Privacy Policy is effective as of July 28, 2017 and will remain in
			effect except with respect to any changes in its provisions in the future, which will be in effect immediately after
			being posted on this page.
			<br />
			<br />
			We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy
			periodically. Your continued use of the service after we post any modifications to the Privacy Policy on this page
			will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified
			Privacy Policy.
			<br />
			<br />
			If we make any material changes to this Privacy Policy, we will notify you by placing a notice on our website.
			<h4>Contact Us</h4>If you have any questions about this Privacy Policy, please contact us. All comments, queries and
			requests relating to our use of your information are welcomed and should be addressed to:{' '}
			<a href="mailto:contact@scratchwork.io">contact@scratchwork.io</a>
		</div>
	</>
);